.hint {
    gap: 0 1rem;
}

.table-item {
    cursor: pointer;
}

.column-item {
    cursor: pointer;
    padding-left: 50px;
}

.properties-item {
    padding-left: 75px;
}