@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Provide sufficient contrast against white background */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Noto Sans Thai', sans-serif;
}

#root {
  height: 100dvh;
  display: flex;
  flex-direction: column;
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.swal2-container {
    z-index: 10000;
}