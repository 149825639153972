.body {
}

.headTBox {
  font-weight: 700;
  color: #73879c;
  font-family: Helvetica, Arial, sans-serif;
}

.header {
  font-family: "Oswald";
  color: #73879c;
  font-size: 18px;
}

.svn-row.active {
  background: #0085a1;
  color: #fff;
}

.svn-row {
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
  background: #eee;
  cursor: pointer;
}

p {
  margin: 0;
  margin-bottom: 10px;
  color: #73879c;
  font-weight: 400;
  line-height: 1.471;
  font-family: "Helvetica Neue";
}

.svn-row:hover {
  background: #ffd800;
}

.svn-result {
  height: 300px;
  overflow-y: auto;
  background: #000;
}

.mat-box {
    border-radius: 5px;
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    background: rgb(0, 0, 0);
    padding: 15px;
    margin-bottom: 10px;
    white-space: pre-line;
}

.headerlabel {
  border-bottom: 1px solid #dee2e6;
  padding: 1rem 1rem;
}

.headerbody {
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
}

.labelfooter {
  justify-content: flex-start;
}
