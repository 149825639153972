.body {
}
.QuickBox {
    display: flex;
    flex-direction: row;
}
.totalserver {
    margin-top: 15px;
    display: flex;
    flex-Direction: column;
}
.total {
    display: flex;
    justify-content: flex-end;
}
.Status {
    text-align: center;
}
.tableadmin {
    font-size: 14px;
}
.Progressbar {
   border: 0.08px solid #73879C;
   width:100px;
   position:relative;

}
.fontProgressbar {
    position: absolute;
    z-index: 100;
    color: black;
    text-align:center;
    transform:translateX(28px);
}
.custom-header-style {
        color: #2196F3;
        text-align:center;
}
.table td {
    color: #73879C;
    text-align: center;
}
.Loader {
    border: 0.1px solid #dee2e669;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.table th, .table td {
    padding: 0.53rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}